@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gradient,
.bgGradient {
  background: linear-gradient(103.44deg,#1345c6 8.01%,#4176ff 60.79%) !important;
}

#transaction-hash {
  color: #333 !important;
}
.pointer {
  cursor: pointer !important;
}

.__text-primary {
  color: "#1345c6" !important;
}

.__bg-gradient {
  background: linear-gradient(70deg, #1345c6, #4176ff)!important;
}

.text-poppins {
  font-family: 'Poppins', sans-serif!important;
}

.search__input {
  padding: 6px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #bbb;
  box-sizing: border-box;
  border-radius: 4px;
}

.search__input:focus {
  outline: none
}

@media screen and (min-width: 800px) {
  .search__input {
    width: 250px;
  }
}