.activ {
  background: linear-gradient(70deg, #e100ff, #0841bd);
  font-size: 15px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-transform: capitalize !important;
}
.linkNemu {
  color: #000;
  line-height: 40px;
}
a {
  text-decoration: none;
  color: #333;
}
a:hover {
  text-decoration: none;
  color: #333;
}
.brnemu {
  background-color: #1a2672;
  color: white;
  border: 1 solid #e100ff;
  margin-left: 0;
  margin-bottom: 100px;
}
.Nav.Link {
  color: white !important;
}
.activDeconnet {
  background-color: #ddd;
  margin: 15px;
  font-size: 15px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-transform: capitalize !important;
}

.linkNave:hover {
  color: white !important;
   background-color: #ddd;
  border-radius: 7px; 
}


@media screen and (max-width:767px){
  .brnemu {
    margin-bottom: 50px;
  }
}