/* .bg {
  background-color:rgb(30, 30, 30);
} */

 .bg::before {
    content: '';
    position: fixed;
    z-index: -1;
    display: block;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color:#fff;
    /* background-image: url("../../images/bg2.jpg");  */
        background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
 
 } 
