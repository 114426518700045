.boxclass {
  background-color: rgb(0, 0, 0);
  overflow: hidden;
}
.text_bienv {
  color: white;
  font-size: 45px;
  margin-top: 20vh;
  text-align: center;
  font-weight: bold;
}
.btnLogin {
  background: linear-gradient(70deg, #e100ff, #0841bd);
  font-size: 15px !important;
  /* padding-left: 50px !important;
  padding-right: 50px !important; */
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  text-transform: capitalize !important;
  min-width: 200px !important;
  height: 28px !important;
}
.btnLogin2 {
  /* background: linear-gradient(70deg, #E100FF, #5D36EA); */
  margin-top: -20px;
  background-color: #5d36ea;
  font-size: 15px !important;
/*   padding-left: 50px !important;
  padding-right: 50px !important; */
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  text-transform: capitalize !important;
  min-width: 200px !important;
  height: 28px !important;
}
.center {
  text-align: center;
  margin-top: -20px;
}
.center1 {
  text-align: center;
  margin-top: -5px;
}
.text1 {
  color: white;
  font-size: 2.125rem !important;
  text-align: center;
}
.text2 {
  color: white;
  font-size: 1.5rem !important;
  text-align: center;
}
.logo {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 40px;
}

.bg1 {
  /* background-image: url(""); */
  background-color: black;
  position: relative;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg2 {
  padding: 30px;
}
.bg3 {
  /* background-image: url("../images/default-background-right.c3ee45f.jpg"); */
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
}

.imageBk {
  display: none;
}
@media (max-width: 960px) {
  .bloc1 {
    display: none;
  }
  .bloc2 {
    max-width: 100% !important;
  }

  .text_bienv {
    margin-top: 1vh !important;
  }

  .imageBk {
    height: 17vh;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 25px;
  }

  .boxContainer {
    display: block !important;
  }
  .bloc3 {
    display: none;
  }
}

/* Login css */

.formF {
  margin-top: -20vh;
  text-align: center;
  padding: 40px;
}
.Formlabel {
  color: antiquewhite;
}
