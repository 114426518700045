.bar1 {
  background: linear-gradient(70deg, #1345c6, #4176ff);
  border-radius: 8px;
  margin-right: 1rem;
}
.bar2 {
  background-color: black;
  border-radius: 15px;
}
.t1 {
  text-align: center;
}
.t2 {
  color: white;
  border: 2px solid white;
  border-radius: 8px;
  padding-top: 2px;
  padding-left: 60px;
  padding-right: 30px;
  margin-top: 60px;
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.H4 {
  padding-top: -1000px;
  padding-bottom: -300px;
}
h3 {
  color: white;
  font-size: 40px;
}
.video {
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  right: 0;
}
/* .gb{
    padding: 20px;
 
    height: 100vh;
    overflow: hidden;
    background-image: url("../images/background.jpg"); 
    background: cover;
  
} */
.btnc2 {
  border: 1px solid white;
  border-radius: 8px;
  background-color: #fff !important;
  font-size: 12px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  text-transform: capitalize !important;
  min-width: 195px !important;
  height: 28px !important;
  text-align: center;
}
.linka {
  text-decoration: none;
  text-align: center;
}
.centerbtn {
  text-align: center;
}
.btnc1 {
  background: linear-gradient(70deg, #e100ff, #0841bd);
  font-size: 15px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  text-transform: capitalize !important;
  min-width: 200px !important;
  height: 28px !important;
  border: 1px solid #0841bd;
  border-radius: 8px;
}

.btnBloc{
  background-color:#242424;
  margin: 15px;
  font-size: 15px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-transform: capitalize !important;
}

.bloc-1 {
  padding-left: 0px !important;
}

.bloc-2 {
  padding-right: 0px !important;
}

.affiliation-bloc-1 {
  margin-left: 130px!important;
  margin-right: 30px!important;

}
.percentage-text {
  font-weight: bold!important;
}

.percentage-sign {
  font-size: 65px;
}
.stats-number {
  font-size: 70px!important;
}

@media screen and (max-width: 768px) {
  .px-md-only{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .affiliation-bloc-1 {
    margin-left: 0px!important;  
    margin-right: 0px!important;

  }
  .affiliation-bloc-1-img {
    margin-top: 3rem;
  }
  .percentage-text {
    font-weight: bold;
    font-size: 4rem!important;
  }
  
  .percentage-sign {
    font-size: 2rem;
  }
  .stats-number {
    font-size: 2.5rem!important;
  }
  .bar1 {
    margin-right: 0rem;
  }
}

@media (max-width: 960px)
{
    .b1{
      max-width: 100% !important;
    }
     /* .b2{
        display: none; 
    } */

    /* .text_bienv{
        margin-top: 1vh !important;
    } */

    /* .imageBk{
        height: 17vh;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 25px;
    } */

    /* .boxContainer{
        display: block !important;
    } */
      /* .bloc3{
        display: none;     
       } */
}
@media screen and (max-width: 992px) {
   /* .b1{
    display: none; 
  }
  .b2{
    display: none; 
  } */
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  /* .b1{
    display: none; 
  } */
  /* .b2{
    display: none; 
  } */
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { }

 /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {  }

 /* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  }

 /* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { }



